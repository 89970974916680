<style lang="scss">
.finish-btn {
  width: 265px;
  height: 36px;
  background: #FF4158;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border: 0;
  margin-top: 10px;
}

.exp-wrapper {
  //position: absolute;
  //left: 0;
  //top: 46px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fff;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.exp-line-status-circle {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 4px;
}

.applier {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 15px;
}

.do-score {
  font-size: 14px;
  color: #4A89DC;
}

.view-score {
  font-size: 14px;
  color: #F86719;
}

.confirm-finish {
  width: 80%;
  left: 10%;
  background: #fff;
}

.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }
}

</style>
<template>
  <div>
    <AppHeader title="实验列表"/>
    <div slot="content" v-infinite-scroll="loadExps"
         infinite-scroll-disabled="loading"
         infinite-scroll-distance="10">
      <div class="line" v-for="(exp,idx) of exps">
        <div class="flex-dir-row exp-line-title">
          {{ exp.equipmentId }}
          <div class="flex-item-auto exp-line-status">
            <div class="exp-line-status-circle"></div>
            <div v-if="exp.endAt!=null">已结束</div>
            <div v-else>实验中</div>
          </div>
        </div>
        <div class="applier">申请人：{{ exp.userName }}</div>
        <div>实验起止时间: {{ beginEnd(exp.beginAt, exp.endAt) }}</div>
        <div class="flex-dir-row">
          实验时长: {{ displaySpent(exp.spent) }}
          <div class="flex-item-auto text-right">
            <div class="view-score" @click="viewScore(exp)" v-if="exp.score>0">已评价</div>
            <div class="do-score" @click="doScore(exp)" v-else>评价</div>
          </div>
        </div>
      </div>
      <div class="line text-center" v-if="exps.length===0">暂无记录</div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import Panel from "@/components/Panel";

export default {
  components: {
    Panel,
    AppHeader,
    InfiniteScroll
  },
  data() {
    return {
      page: 1,
      exps: [],
      loading: false,
      noMore: false,
      curExp: null,
      popupVisible: false,
      finalAccounts: false
    }
  },
  mounted() {
    this.initExps()
  },
  methods: {
    initExps() {
      this.exps = []
      this.page = 1
      this.noMore = false
      this.loadExps()
    },
    loadExps() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      data.append('self', 'false')
      this.$http.post(`/api/experiment/list`, data).then(res => {
        if (res.data && res.data.length > 0) {
          const exps = res.data
          that.exps = that.exps.concat(exps)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    displaySpent(spent) {
      let totalSeconds = Math.floor(spent / 1000)
      let hours = Math.floor(totalSeconds / 3600)
      let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      let seconds = totalSeconds - hours * 3600 - minutes * 60
      return this.fillZero(hours) + ':' + this.fillZero(minutes) + ':' + this.fillZero(seconds)
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY.MM.DD HH:mm')
    },
    fillZero(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    viewScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id,
          view: true
        }
      })
    },
    doScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id
        }
      })
    },
    doEnd(id){
      const that = this
      this.$http.post(`/api/experiment/end?id=${id}&finalAccounts=${that.finalAccounts}`).then(res => {
        that.initExps()
      })
    },
    endExp() {
      const that = this
      that.popupVisible = true
    },
    cancelEnd(){
      this.popupVisible = false
    }
  }

}
</script>
